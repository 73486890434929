import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import config from 'Config/AppConfig'
import { toast } from 'react-toastify'
import { ErrorToast, StatusWrapper, StyledBadge } from 'Themes/ScufStyledComponents'

import LicenseInstallerModal from '../../../LicenseInstaller'
import {
  LicenseBundleContainer,
  StyledDataTable,
  TableContainer
} from './licenseBundles.styles'
import Loader from 'Components/Loader'
import { 
  CONFIRMATION_TAB,
  SCHEDULE_TAB,
  DEVICES_TAB
} from 'Features/LicenseInstaller/licenseInstaller.constants'

function _dateFormat (cellData) {
  const value = cellData.value ? moment(cellData.value).locale(window.navigator.language).format('MM-DD-YYYY HH:mm:ss') : 'Not Available'
  return <div>{value}</div>
}

function _getCount (cellData) {
  const count = cellData.rowData.deviceLicensesList[0].deviceStatusInfo.length
  return <div>{count}</div>
}

function _renderSite (siteName) {
  const outValue = siteName.value.split('/')
  if (outValue.length > 1) { return <p>{(outValue[outValue.length - 1]).trim()}</p> }
  return <p>{outValue}</p>
}

function Render ({ value }) {
  return <p>{value}</p>
}

function _renderActivationId (cellData) {
    let activationIds = cellData.rowData.deviceLicensesList
    const activationId = activationIds.map((val, index) => {
        return <div key={index}>{val['activationId']}{index<activationIds.length-1 && <span>,</span>}</div>
      })
    return activationId
 }

function _renderSatus(cellData) {  
  let BundleStatusType = {
    color: '',
    statusText: `${cellData.rowData.jobStatus}`
  }
  switch (cellData.rowData.jobStatus) {
  case 'Created':
    BundleStatusType = {
      ...BundleStatusType,
      color: 'grey',
    } 
    break;
  case 'Processing':
    BundleStatusType = {
      ...BundleStatusType,
      color: 'blue',
    } 
    break;
  case 'Error':
    BundleStatusType = {
      ...BundleStatusType,
      color: 'red',
    } 
    break;
  default:
    BundleStatusType = {
      statusText: 'Completed',
      color: 'green',
    } 
    break;
  }

  return (
  <StatusWrapper>
    {BundleStatusType.color && <StyledBadge color={BundleStatusType.color} empty />}
    {BundleStatusType.statusText}
  </StatusWrapper>
  )
}

let columns = [
  { field: 'bundleName', header: 'Bundle Name', initialWidth: '26%', render: Render },
  { field: 'deviceStatusInfo', header: ' Device Count', initialWidth: '24%', render: _getCount },
  { field: 'activationId', header: 'Activation Id', initialWidth: '25%', render: _renderActivationId },
  { field: 'modifiedOn', header: 'Last Updated', initialWidth: '25%', render: _dateFormat },
  { field: 'status', header: 'Status', initialWidth: '25%', render: _renderSatus }

]

if (!config.nonSinaps) {
  columns = [
    { field: 'bundleName', header: 'Bundle Name', initialWidth: '16%', render: Render },
    { field: 'deviceStatusInfo', header: ' Device Count', initialWidth: '14%', render: _getCount },
    { field: 'activationId', header: 'Activation Id', initialWidth: '20%', render: _renderActivationId },
    { field: 'modifiedOn', header: 'Last Updated', initialWidth: '15%', render: _dateFormat },
    { field: 'siteName', header: 'Org/Site Name', initialWidth: '13%', render: _renderSite },
    { field: 'createdBy', header: 'Created By', initialWidth: '17%', render: Render },
    { field: 'modifiedBy', header: 'Modified By', initialWidth: '17%', render: Render },
    { field: 'status', header: 'Status', initialWidth: '25%', render: _renderSatus }
  ]
}

const LicenseBundles = ({
  loading,
  licenseBundles,
  getLicenseBundles,
  exportInProgress,
  updateLicenseInstaller,
  licenseBundleExport,
  updateExportInProgress,
  getUserDetails,
  currentUserEmail,
  getDevices,
  currentUserAccessibleDeviceList,
  updateSelectedSoftwares
}) => {
  const [selectedRows, setSelectedRows] = useState([])
  const [isLicenseInstallerModalOpen, setIsLicenseInstallerModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const { Item } = StyledDataTable.ActionBar
  const mode = 'edit'
  useEffect(() => {
    getLicenseBundles()
    if (!config.nonSinaps)
    {
      getUserDetails()
    }
  }, [getLicenseBundles, getUserDetails, licenseBundleExport])

  useEffect(() => {
    if (selectedRows[0] && !config.nonSinaps) {
      const devicesInBundle = selectedRows[0].deviceLicensesList[0].deviceStatusInfo
      let bundleDeviceCount = devicesInBundle.length;
      let matchedElements = 0;

      currentUserAccessibleDeviceList.filter((userAccessibleDevice) => {
        devicesInBundle.some((bundleDevice) => {
          if (bundleDevice.serialNumber === userAccessibleDevice.serialNumber) {
            matchedElements++;           
          }
        })
      })

      if (matchedElements === bundleDeviceCount) {
        setIsLicenseInstallerModalOpen(true)
        updateLicenseInstaller(selectedRows, mode)
        setActiveTab(4)
      }
      else {
        toast(<ErrorToast message='One or more devices which are part of the bundle are not accessible.' />)
      }
    }
  }, [currentUserAccessibleDeviceList])

  const handleCloseModalCallback = useCallback(() => {  
    setIsLicenseInstallerModalOpen(false)
    setSelectedRows([])
    getLicenseBundles()
    setActiveTab(0)
  }, [setIsLicenseInstallerModalOpen, setSelectedRows, getLicenseBundles])

  const handleInstallCallback = useCallback((index) => {
    if (index == 4 && !config.nonSinaps) {
      const selectedData = [...selectedRows]            
      getDevices(selectedData[0].siteId)
    } else {           
      updateLicenseInstaller(selectedRows, mode)
      setIsLicenseInstallerModalOpen(true)
      setActiveTab(index)
    }
  }, [selectedRows, setIsLicenseInstallerModalOpen, updateLicenseInstaller])

  const handleDownloadback = useCallback(
    () => {
      const selectedData = [...selectedRows]
      const bundleData = {
        bundleId: selectedData[0].id,
        deviceType: selectedData[0].compatibleDeviceType,
        siteId: selectedData[0].siteId
      }
      updateExportInProgress()
      setSelectedRows([])
      licenseBundleExport(bundleData)
    },
    [selectedRows, licenseBundleExport]
  )

  const initActiveTab = useCallback(() => {
    setActiveTab(0)
  }, [setActiveTab])

  return (
    <LicenseBundleContainer>
      {exportInProgress && <Loader loading={exportInProgress} />}
      <TableContainer>
        <StyledDataTable
          data={licenseBundles}
          loading={loading}
          search={true}
          searchPlaceholder='Search for license Bundles'
          selection={selectedRows}
          selectionMode='single'
          onSelectionChange={(rows) => setSelectedRows(rows)}
          scrollHeight='50vh'
          scrollable={true}
          resizableColumns={true}
          columnResizeMode='expand'
          reorderableColumns={true}
        >
          {columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
                align='left'
                responsive
                flexGrow={0.75}
                flexShrink={0.75}
              />)}
          <StyledDataTable.ActionBar clearSelectionOnClose={true}>
            <Item
              icon='edit'
              iconSize='small'
              content='Edit'
              disabled={
                selectedRows[0]?.jobStatus !== 'Completed' ? true 
                : 
                  selectedRows.length != 0 && 
                  !config.nonSinaps && 
                  selectedRows[0].createdBy.trim() !== currentUserEmail.trim() 
                }     
              onClick={() => handleInstallCallback(DEVICES_TAB)}
            />
            {
              config.nonSinaps
                ? <Item
                  icon='file-download'
                  iconSize='small'
                  content='Export'
                  onClick={() => handleDownloadback()}
                  />
                : <>
                  <Item
                    icon='calendar'
                    iconSize='small'
                    content='Schedule'
                    disabled={
                      selectedRows[0]?.jobStatus !== 'Completed' ? true 
                      : selectedRows.length != 0 && 
                      !config.nonSinaps && 
                      selectedRows[0].createdBy.trim() !== currentUserEmail.trim()
                    }                   
                    onClick={() => handleInstallCallback(SCHEDULE_TAB)}
                  />
                  <Item
                    icon='firmware-update'
                    iconSize='small'
                    content='Install Now'
                    disabled={selectedRows[0]?.jobStatus !== 'Completed' ? true : false}
                    onClick={() => handleInstallCallback(CONFIRMATION_TAB)}
                  />
                  </>
            }

          </StyledDataTable.ActionBar>
        </StyledDataTable>
      </TableContainer>
      <LicenseInstallerModal
        openModal={isLicenseInstallerModalOpen}
        handelClose={handleCloseModalCallback}
        activeTab={activeTab}
        resetActiveTab={initActiveTab}
        mode={mode}
      />
    </LicenseBundleContainer>
  )
}

export default LicenseBundles
